<template>
  <div class="main-div vasion-calc-height">
    <div class="inner-div">
      <VasionEmptyScanner />
      <div class="vasion-large-message">
        {{ greetingMessage }}
      </div>
      <div v-if="showSetupInstructions">
        <div class="vasion-page-subheader setup-instructions">
          {{ setupInstructions }}
        </div>
        <div>
          <ol class="instruction-list">
            <li>Locate the "Send to Vasion" setup file and execute it</li>
            <li>Browse to the directory where Send to Vasion was installed</li>
            <li>Execute VasionConnection.exe</li>
            <li>Enter the following for the Vasion API URL: <span class="vasion-url">{{ vasionAPIURL }}</span></li>
            <li>Enter your credentials</li>
            <li>Click "Test Authentication"</li>
            <li>Once you receive a successful response, click "OK" to save</li>
          </ol>
        </div>
        <div class="button-div">
          <VasionButton
            id="new-lookup-button"
            :isFullWidth="true"
            :classProp="'primary'"
            @vasionButtonClicked="showSetupInstructions = !showSetupInstructions"
          >
            OK
          </VasionButton>
        </div>
      </div>
      <div v-else>
        <div class="vasion-page-subheader instructional-message-div">
          {{ instructionalMessage }}
        </div>
        <div class="button-div">
          <VasionButton
            id="new-workflow-button"
            :isFullWidth="true"
            :classProp="'primary'"
            class="setup-scanner"
            @vasionButtonClicked="setupScannerClick"
          >
            <VasionScanIcon /> Setup Scanner
          </VasionButton>
        </div>
        <div class="button-div">
          <VasionButton
            id="new-lookup-button"
            :isFullWidth="true"
            :classProp="'primary'"
            @vasionButtonClicked="iHaveAScannerClick"
          >
            I Have a Scanner
          </VasionButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyCapture',
  data: function () {
    return {
      greetingMessage: 'Hello!',
      showSetupInstructions: false,
    }
  },
  computed: {
    instructionalMessage() {
      return 'It feels a little empty, setup a scanner to get started!'
    },
    setupInstructions() {
      return 'Your scanner setup file is being downloaded. Please follow these instructions to complete setup:'
    },
    vasionAPIURL() {
       return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_API}`
    },
  },
  async created() {
    this.greetingMessage = await this.$store.dispatch('common/getEmptyAppGreeting')
  },
  methods: {
    iHaveAScannerClick() {
      this.$emit('iHaveAScannerClicked')
    },
    setupScannerClick() {
      this.showSetupInstructions = true
      this.$store.dispatch('capture/getInstaller')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .button-div {
    margin: auto;
    margin-top: 40px;
    width: 288px;

    .vasion-button {
      a, button {
        height: 56px;
        border-radius: 8px;

        .md-button-content {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          line-height: 56px;
          vertical-align: middle;

          svg {
            fill: $white;
            line-height: 56px;
            vertical-align: middle;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  .inner-div {
    margin-top: 84px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .vasion-empty-scanner {
    margin-top: 84px;
    width: 100%;
  }

  .instructional-message-div {
    margin-top: 24px;
  }

  .setup-instructions {
    margin-top: 24px;
  }

  .main-div {
    height: calc(100vh - 200px);
    background-color: white;
    text-align: center;
    width: 100%;
  }

  .instruction-list {
    margin-top: 24px;
    margin-left: 130px;
    text-align: left;

    li {
      padding-left: 10px;
    }
  }

  .vasion-url {
    font-weight: bold;
  }
</style>
